.error {
  align-self: center;

  min-height: auto !important;
  margin-top: 20px !important;
  margin-bottom: 24px !important;

  text-align: center;

  border-radius: 16px;
  background-color: var(--dark-08);
}
