.container {
  display: flex;

  gap: 4px;
}

.linkContainer {
  padding: 5px 14px;

  cursor: pointer;
  user-select: none;

  color: var(--white-48);
  border-radius: 20px;
}

.linkContainer:hover {
  color: var(--white-64);
}

.linkContainerActive {
  color: var(--white);
  border-color: var(--white);
  background-color: rgba(255, 255, 255, 0.12);
  box-shadow: var(--inset-shadow);
  backdrop-filter: blur(1px);
}

.linkContainerActive:hover {
  color: var(--white);
  background-color: rgba(255, 255, 255, 0.16);
}

.linkText {
  cursor: inherit;
  white-space: nowrap;

  color: inherit;
}

.greenTab {
  color: var(--green);
}

.greenTab.linkContainerActive {
  color: var(--green);
  background-color: var(--green-20);
}

.greenTab:hover {
  color: var(--white);
}
