.followSuggestionsBannerWrapper {
  position: relative;

  display: flex;
  flex-direction: column;
}

.description {
  line-height: 18px !important;

  margin: 4px 0 10px;
}

.followSuggestionsList {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  gap: 20px;
}

.usernameLink {
  display: flex;
  align-items: center;
  gap: 4px;
}

.username {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.userInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;

  /* 100% - avatar - avatar padding - plus icon width - icon gap */
  width: calc(100% - 48px - 12px - 32px - 8px);
  margin-left: 12px;
}

.suggestionUser {
  display: flex;
  align-items: flex-start;
}

.avatarContainer {
  width: 48px;
  height: 48px;
}

.userAdditionalInfo {
  display: flex;
}

.followersCount {
  white-space: nowrap;

  color: var(--dark-48);
}

.loaderWrapper {
  align-items: center;
}

.followButton {
  width: 32px;
  height: 32px;
  margin: auto;
  margin-top: 0;
  margin-right: 0;
  padding: 0;
}

.showMoreButton {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;

  display: flex;
  align-self: end;

  width: 100% !important;
  margin-top: 8px;
  padding: 8px 0;

  cursor: pointer;

  opacity: 1;
  color: var(--green);
  background: none;
}

.updateFeedButton {
  width: 100% !important;
  margin-top: 18px;
  padding: 8px 20px !important;

  opacity: 1;
}

.closeIcon {
  position: absolute;
  top: 14px;
  right: 23px;

  width: 24px;
  height: 24px;
  stroke: var(--dark-48);
}

.streamerCheck {
  min-width: 14px;
  min-height: 14px;
}
