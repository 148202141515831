.container {
  display: flex;
}

.buttonContainer {
  width: 100% !important;
  height: 40px;
}

.discordColored {
  background-color: var(--discord-color) !important;
}

.discordColored:hover {
  background-color: var(--discord-hover-color) !important;
}

.battlenetColored {
  background-color: var(--battlenet-color) !important;
}

.battlenetWithoutIcon {
  width: fit-content;
  height: 32px;
}

.battlenetColored:hover {
  background-color: var(--battlenet-hover-color);
}

.twitchColored {
  background-color: var(--twitch-color);
}

.twitchColored:hover {
  background-color: var(--twitch-hover-color);
}

.smallContainer {
  height: 32px;
}

.buttonRiotContainer {
  height: 32px;
}

.selectContainer {
  position: relative;
}

.select {
  position: absolute;
  z-index: 2;
  bottom: -18px;
  left: 17%;

  visibility: hidden;

  width: 120px;
  max-height: 128px;
}

.selectOpen {
  visibility: visible;
}

.option {
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 32px;
  padding: 8px 12px;

  text-align: center;

  color: var(--white);
}

@media (max-width: 540px) {
  .smallContainer {
    height: 44px !important;
  }
}
