.container {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 100%;

  cursor: not-allowed;

  border-radius: inherit;
  background-color: inherit;
  gap: 8px;
}

.errorIcon {
  --icon-color: var(--yellow);
  width: 48px;
  height: 48px;
}

.errorText {
  text-align: center;

  color: var(--yellow);
}
