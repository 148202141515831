.container {
  position: absolute;
  z-index: 200;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 100%;

  cursor: wait;
  user-select: none;

  border-radius: inherit;
  background-color: inherit;
  gap: 8px;
}

.loadingText {
  text-align: center;

  color: var(--dark-64);
}
