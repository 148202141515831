.followSuggestionsBannerWrapper {
  align-items: center;

  width: 100%;
  margin-bottom: 24px;
  padding: 16px 22px 9px 14px;

  border-radius: 16px;
  background: var(--dark-08);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.04);
}

.error {
  align-self: center;

  min-height: auto !important;
  margin-top: 8px !important;
  margin-bottom: 24px !important;
  padding: 16px;

  text-align: center;

  border-radius: 16px;
  background-color: var(--dark-08);
}
