.container {
  --sticky-margin: calc(-1 * var(--wrapper-padding));

  /* position: sticky; */
  z-index: 10;
  top: var(--wrapper-offset-top);

  /* margin: var(--sticky-margin); */
  /* margin-bottom: 28px; */
  /* padding: 0 16px; */

  /* border-radius: var(--feed-wrapper-border-radius) var(--feed-wrapper-border-radius) 0 0; */
  /* background-color: var(--color-wrapper-bg); */
  /* box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.04), 0 1px 0 var(--dark-12); */
}
