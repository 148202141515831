.container {
  --mobile-horizontal-padding: 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  width: 100%;
}

.headerText {
  margin-top: 16px;
  padding: 0 var(--mobile-horizontal-padding);
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding-bottom: 65px;

  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.wrapperPlayers {
  padding: 0 var(--mobile-horizontal-padding);

  background-color: var(--dark-08);
  box-shadow: var(--inset-shadow);
}

.mobileGameFeedTabsContainer {
  margin-bottom: 16px;
  padding: 0 var(--mobile-horizontal-padding);
}
