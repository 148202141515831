.buttonsWrapper {
  display: flex;
  flex-direction: column;

  width: 100%;

  transition: max-height 800ms ease;
  gap: 8px;
}

.buttonShowMore {
  width: 100%;
  height: 40px;
}
