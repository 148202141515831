.section {
  margin-top: 24px;
}

.gameIcon {
  align-self: center;

  width: 24px;
  height: 24px;
}

.homeIcon {
  align-self: center;

  width: 24px;
  height: 24px;
  stroke: var(--dark-48);
}

.dateTimePicker {
  display: grid !important;
  grid-gap: 8px;
  gap: 8px;
  grid-template-columns: repeat(4, 1fr) !important;
}

.gameSelectWithHome {
  background-color: var(--dark-08);
}
