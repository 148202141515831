.header {
  position: sticky;
  z-index: 2;
  top: 0;

  display: flex;
  justify-content: flex-end;

  width: 100%;
  padding-top: 20px;
  padding-right: 22px;
  padding-bottom: 10px;

  background-color: var(--dark);
  gap: 10px;
}

.titleWithDescription {
  display: flex;
  flex-direction: column;

  margin-top: 10px;
}

.title {
  font-weight: 700 !important;
  line-height: 30px !important;
}

.description {
  line-height: 30px !important;

  margin-bottom: 16px;

  color: var(--dark-48);
}

.authButton {
  width: fit-content;
}

.authButton:first-child {
  background: none;
}

.authButton:first-child > * {
  color: var(--white) !important;
}

.authButton:first-child:hover > * {
  color: var(--main-green) !important;
}

.authButton:last-child {
  padding: 7px 37px;

  background-color: var(--main-green);
}

.authButton:last-child:hover > * {
  color: var(--dark) !important;
}

.authButton:last-child:hover {
  background-color: var(--white) !important;
}

.title:nth-child(2) {
  margin-bottom: 20px;

  white-space: pre;

  color: var(--dark-48);
  background: linear-gradient(var(--dark-64), var(--dark-48));
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sectionTitle {
  white-space: pre;
}

.gameCard {
  position: relative;

  display: flex;
  flex: 1 0;
  flex-direction: column;
  justify-content: flex-end;

  width: 100%;
  min-width: 188px !important;
  max-width: 188px !important;
  height: 220px !important;

  border-radius: 12px;

  transition: transform 100ms ease;
}

.gameCardImage {
  position: absolute;

  width: 100%;
  height: 100%;

  opacity: 0.8;
  border-radius: 12px;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}

.gameCard:hover > .gameCardImage {
  opacity: 1 !important;
}

.gameCardImageShadow {
  position: absolute;
  bottom: 0;

  width: 100%;
  height: 122px;

  background: linear-gradient(180deg, rgba(5, 5, 5, 0) 0%, rgba(5, 5, 5, 0.00691776) 6.67%, rgba(5, 5, 5, 0.028408) 13.33%, rgba(5, 5, 5, 0.0653279) 20%, rgba(5, 5, 5, 0.117929) 26.67%, rgba(5, 5, 5, 0.18542) 33.33%, rgba(5, 5, 5, 0.265507) 40%, rgba(5, 5, 5, 0.354153) 46.67%, rgba(5, 5, 5, 0.445847) 53.33%, rgba(5, 5, 5, 0.534493) 60%, rgba(5, 5, 5, 0.61458) 66.67%, rgba(5, 5, 5, 0.682071) 73.33%, rgba(5, 5, 5, 0.734672) 80%, rgba(5, 5, 5, 0.771592) 86.67%, rgba(5, 5, 5, 0.793082) 93.33%, rgba(5, 5, 5, 0.8) 100%);
}

.gameCardShadow {
  position: absolute;
  top: 1px;

  display: flex;

  width: 100%;
  height: 220px;
}

/* .gameCard:hover > .gameCardShadow {
  background: linear-gradient(180deg, rgba(5, 5, 5, 0) 0%, rgba(5, 5, 5, 0.0086472) 6.67%, rgba(5, 5, 5, 0.03551) 13.33%, rgba(5, 5, 5, 0.0816599) 20%, rgba(5, 5, 5, 0.147411) 26.67%, rgba(5, 5, 5, 0.231775) 33.33%, rgba(5, 5, 5, 0.331884) 40%, rgba(5, 5, 5, 0.442691) 46.67%, rgba(5, 5, 5, 0.557309) 53.33%, rgba(5, 5, 5, 0.668116) 60%, rgba(5, 5, 5, 0.768225) 66.67%, rgba(5, 5, 5, 0.852589) 73.33%, rgba(5, 5, 5, 0.91834) 80%, rgba(5, 5, 5, 0.96449) 86.67%, rgba(5, 5, 5, 0.991353) 93.33%, #050505 100%);
} */

.gameCardContent {
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  height: 120px;
  padding: 16px 52px 16px 16px;
  gap: 2px;
}

.gameCardButton {
  position: absolute;
  right: 23px;

  width: 32px;
  height: 32px;

  cursor: pointer;

  border-radius: 50%;
  fill: var(--white);
}

.gameCardButton:hover {
  fill: rgba(255, 255, 255, 0.659);
}

.gameCardTitle {
  font-weight: 700 !important;

  cursor: pointer;
}

.soon {
  position: absolute;
  z-index: 1;
  top: 16px;
  right: 16px;

  padding: 4px 6px;

  text-transform: uppercase;

  color: var(--dark);
  border-radius: 4px;
  background-color: var(--main-green);
}

.tabsWrapper {
  z-index: 100;
  top: 0;

  padding-top: 24px;

  background-color: var(--dark);
}

.tabs {
  width: auto;
  padding: 0 24px;
  padding-top: 16px;
  padding-bottom: 0;

  border-radius: 16px;
  background-color: var(--dark-08);
}

.tabs > a:nth-child(5) {
  margin: auto;
  margin-right: 0;
  margin-bottom: 10px;
}

.tabMerchant {
  margin-top: 0 !important;
}

.mainSection {
  --right-content-width: 270px;
  --main-content-gap: 32px;
  display: flex;
  flex-direction: column;
}

.mainSectionContent {
  display: flex;

  gap: var(--main-content-gap);

  min-height: 700px !important;
}

.content {
  flex: 1;

  width: calc(100% - var(--right-content-width) - var(--main-content-gap));
  margin-bottom: 16px;

  border-radius: 16px;
  box-shadow: var(--inset-shadow);
}

.innerContent {
  margin-top: 16px;
  padding-top: 0;
}

.rightContent {
  max-width: var(--right-content-width);
}

.banners {
  position: sticky;
  top: 0;

  display: flex;
  flex-direction: column;

  width: var(--right-content-width);
  padding-top: 24px;
  gap: 20px;
}

.featuresSection {
  display: flex;

  width: 100%;
  height: fit-content;
  margin-top: 12px;
  margin-bottom: 56px;
  gap: 5px;
}

.feature {
  position: relative;

  min-height: 180px;

  border-radius: 16px;
  background-color: var(--dark-08);
  box-shadow: inset 0 1px 0 hsl(0deg 0% 100% / 4%);
}

.feature:first-child {
  flex: 2;
}

.feature:nth-child(2) {
  flex: 1;
}

.featureContentWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 8px;

  padding: 32px;
  padding-top: 0;
}

.columnFeatureContentWrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;

  padding: 32px;
  padding-top: 0;
}

.defaultFeature {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 32px;
}

.defaultFeature > button {
  margin: auto;
  margin-right: 0;
  margin-bottom: 0;
}

.gameFeature {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
}

.button {
  width: fit-content;
  height: fit-content;
  padding: 12px 32px;
}

.featureImage {
  position: relative;

  width: 100%;
  height: 100px;
  max-height: 100px;

  border-radius: 16px 16px 0 0;
  background-position: center;
  background-size: cover;
}

.imageShadow {
  position: absolute;
  bottom: -1px;

  width: 100%;
  height: 100%;

  background: linear-gradient(180deg, #161616 0%, rgba(22, 22, 22, 0.991353) 6.67%, rgba(22, 22, 22, 0.96449) 13.33%, rgba(22, 22, 22, 0.91834) 20%, rgba(22, 22, 22, 0.852589) 26.67%, rgba(22, 22, 22, 0.768225) 33.33%, rgba(22, 22, 22, 0.668116) 40%, rgba(22, 22, 22, 0.557309) 46.67%, rgba(22, 22, 22, 0.442691) 53.33%, rgba(22, 22, 22, 0.331884) 60%, rgba(22, 22, 22, 0.231775) 66.67%, rgba(22, 22, 22, 0.147411) 73.33%, rgba(22, 22, 22, 0.0816599) 80%, rgba(22, 22, 22, 0.03551) 86.67%, rgba(22, 22, 22, 0.01) 93.33%, rgba(22, 22, 22, 0) 100%);

  transform: rotate(-180deg);
}

.featureText {
  color: var(--dark-64);
}

.groupsCount {
  opacity: 0.6;
}

.firstSectionDescriptions {
  display: flex;
  flex-direction: column;

  text-align: start;
  gap: 20px;
}

.firstSectionDescription {
  display: flex;
  align-items: center;
  gap: 16px;
}

.sectionDescription {
  flex: 1;

  max-width: 430px;
}

.firstSectionIcon {
  stroke: var(--dark-48);

  width: 24px;
  height: 24px;
}

.sectionDescription {
  flex: 1;

  max-width: 430px;
}

.firstColor {
  color: var(--dark-64);
  stroke: var(--dark-64);
}

.createGroupLink {
  font-weight: 700 !important;

  margin-top: 8px;

  cursor: pointer;

  color: var(--main-green);
}

.createGroupLink:hover {
  text-decoration: underline;
}

.gameLogo {
  position: relative;

  display: flex;

  margin-top: 32px;
  gap: 2px;
}

.gameLogoElement {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 96px;
  height: 96px;

  border-radius: 16px;
  background: rgb(255, 255, 255, 0.1);
}

.gameLogoPlus {
  position: absolute;
  top: 36px;
  left: 85px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  border-radius: 16px;
  background-color: var(--main-green);
}

.gameLogoPlusIcon {
  stroke: var(--white);
  fill: var(--white);

  width: 10px;
  height: 10px;
}

.gameIcon {
  width: 47px;
  height: 47px;
}

.logoIcon {
  stroke: var(--white);
  fill: var(--white);
}

.advantagesHeader {
  margin-bottom: 16px;

  color: var(--white) !important;
}

.lfgAdvantages {
  display: flex;
  flex-direction: column;
}

.advantage {
  display: flex;
  flex-direction: column;

  padding-top: 12px;
  padding-bottom: 12px;

  border-bottom: 1px solid var(--dark-12);
}

.advantage:nth-child(2) {
  padding-top: 0 !important;
}

.advantage:last-child {
  border-bottom: none !important;
}

.advantageHeader {
  display: flex;
  align-items: center;
  gap: 12px;

  margin-bottom: 8px;
}

.advantageIcon {
  width: 20px;
  height: 20px;
  fill: var(--main-green) !important;
  stroke: var(--main-green) !important;
}

.advantageTitle {
  color: var(--white) !important;
}

.advantageDescription {
  color: var(--dark-48) !important;
}

.authDescription {
  margin-bottom: 20px;

  color: var(--dark-48) !important;
}

.error {
  align-self: center;

  margin: auto;
  margin-top: 30px;

  text-align: center;

  border-radius: 16px;
  background-color: var(--dark-08);
}

.externalLinkContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 8px 0;
  padding: 16px;

  border-radius: 16px;
  background-color: var(--dark-08);
}
