.container {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;

  gap: 12px;

  margin-top: 14px;

  /* Below block there is 24px,
   * horizontal scrollbar, that would appear on scroll is around 14px
   * so 14px is padding, 10px is margin
   */
  margin-bottom: 10px;
  padding: 2px;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.container::-webkit-scrollbar {
  display: none;
}
