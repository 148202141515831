
.slickConteinerWrapper {
  position: relative !important;
}

.slickConteiner {
  overflow: hidden !important;
}

.contentWrapper {
  position: static !important;

  overflow-y: auto !important;

  width: 100% !important;
  margin-bottom: 32px !important;
}

.contentWrapper::-webkit-scrollbar {
  width: 100% !important;
  height: 6px;

  background-color: var(--dark);
}

.contentWrapper::-webkit-scrollbar-thumb {
  border-radius: 9em;
  background-color: var(--dark-12);
}

.content {
  display: flex !important;
  overflow-x: scroll !important;
  flex-wrap: nowrap !important;

  width: fit-content !important;
  padding-bottom: 1px !important;

  transition-timing-function: ease-in-out;
  transition-property: transform;

  gap: 4px;
}

.content > * {
  flex: 1 0 !important;
}

.content::-webkit-scrollbar {
  height: 0;
}

.sliderArrow {
  position: absolute !important;
  z-index: 5 !important;
  top: 94px !important;

  transition: visibility 200ms ease-in-out, opacity 200ms ease-in-out !important;
}

.leftArrow {
  left: 20px !important;
}

.rightArrow {
  right: 20px !important;
}
