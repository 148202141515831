.container {
  --mobile-horizontal-padding: 16px;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  width: 100vw;
  min-height: 100vh;
}

.loginMotivation {
  position: fixed;
  z-index: 100;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 12px 24px 12px 0;

  color: var(--dark) !important;
  background-color: var(--green);
}

.loginMotivationText {
  margin-right: 8px;
  margin-left: var(--mobile-horizontal-padding);

  color: inherit !important;
}

.loginMotivationButton {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;

  min-width: 120px;
  height: fit-content;
  padding: 7px 16px;

  color: var(--green);
  border-radius: 6px;
  background-color: var(--dark);
}

.loginButtonMotivationText {
  white-space: nowrap;

  color: inherit !important;
}
