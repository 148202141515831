.container {
  display: flex;
  gap: 22px;

  width: 100%;
  height: 60px;
  padding: 16px var(--mobile-horizontal-padding);

  border-bottom: 1px solid var(--white-12);
}

.logoContainer {
  flex-grow: 1;
}

.logoIcon {
  width: fit-content;
  height: 28px;
}

.buttonsContainer {
  display: flex;
  flex-shrink: 0;
}

.headerButton {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 8px;

  cursor: pointer;

  background-color: transparent;
}

.buttonLabel {
  color: var(--dark-64);
}

.buttonIcon {
  --icon-color: var(--dark-64);
  width: 28px;
  height: 28px;
}

.buttonIcon:active {
  --icon-color: var(--white);
}

.userImg {
  width: 28px;
  height: 28px;

  box-shadow: 0 0 1px 1px var(--dark-64);
}

.createEventIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 26px;
  height: 26px;

  border: 2px solid var(--white);
  border-radius: 4px;
}

.createEventIcon {
  width: 16px;
  height: 16px;
}

.headerThreeDots {
  width: 28px;
  height: 28px;

  transform: rotate(90deg);
}
