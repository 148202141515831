.container {

  /* width: 100%; */
  /* max-width: 60px; */
}

.control {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;

  height: 48px;
  padding: 8px 12px;

  cursor: pointer;

  border-radius: 6px;
  background-color: var(--dark-next, var(--dark-12));

  transition: background-color 100ms ease;
}

.control:focus {
  outline: 1px solid var(--green);
}

.control:hover {
  background-color: var(--dark-20);
}

.control:active {
  background-color: var(--dark-32);
}

.controlDisabled,
.controlDisabled:hover,
.controlDisabled:active {
  cursor: not-allowed;
  pointer-events: none;

  /* background-color: var(--dark-08); */
  opacity: 0.48;
}

.controlDisabled > * {
  pointer-events: none;
}

.valueContainer {
  display: flex;
  /* align-items: center; */
  flex-direction: column;

  width: 100%;
}

.iconContainer {
  flex-shrink: 0;

  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.singleValue {
  flex-shrink: 0;

  user-select: none;

  color: var(--white);
}

.valueLabel {
  user-select: none;

  color: var(--dark-48);
}

.valueLabelError {
  color: var(--yellow);
}

.valueLabelRequired:after {
  content: '*';

  color: var(--main-green);
}

@keyframes fadeIn {
  0% {
    opacity: 0;

    transform: translateY(-16px);
  }

  100% {
    opacity: 1;

    transform: translateY(0);
  }
}

.menu {
  position: absolute;
  z-index: 1000;

  /* width: 100%; */
  min-width: 50px;
  /* m max-width: 300px; */
  margin: 8px 0;

  border-radius: 10px;
  background-color: var(--dark-20);
  box-shadow: 0 12px 24px var(--black-16), inset 0 1px 0 var(--white-5);

  animation: fadeIn 200ms ease-in-out;

  /* backdrop-filter: blur(30px); */
}

.menuList {
  position: relative;

  overflow-y: auto;

  max-height: 300px;

  border-radius: inherit;
}

.menuList::-webkit-scrollbar {
  width: 4px;
  height: 0;
}

.menuList::-webkit-scrollbar-track {
  margin: 4px;

  background-color: transparent;
}

.menuList::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--white-60);

  transition: background-color 200ms ease;
}

.menuList::-webkit-scrollbar-thumb:hover {
  background-color: var(--white-80);
}

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 32px;
  padding: 0 12px;

  cursor: default;
  user-select: none;
}

.specialOption {
  height: 48px !important;
  padding: 12px !important;
}

.option:hover {
  background-color: var(--dark-32);
}

.optionFocused {
  background-color: var(--dark-48);
}

.option:active {
  background-color: var(--dark-64);
}

.optionImage {
  width: 24px;
  height: 24px;
}

.input {
  font-size: 16px;
  line-height: 20px;

  color: var(--white);
  border: none;
  outline: none;
  background-color: transparent;
}

.input:not(:focus) {
  cursor: pointer;
}

.input::placeholder {
  text-overflow: ellipsis;

  color: var(--white);

  transition: color 100ms ease;
}

.input:focus::placeholder {
  text-overflow: ellipsis;

  color: var(--dark-64);
}

.input:placeholder-shown {
  text-overflow: ellipsis;
}

.indicator {
  display: flex;
  align-items: center;
}

.indicatorIcon {
  width: 16px;
  height: 16px;

  stroke: var(--dark-48);
}

.mobileNativeSelectWrapper {
  position: relative;

  width: 100%;
}

.mobileNativeSelect {
  font-size: 16px;
  line-height: 20px;

  width: 100%;
  height: 48px !important;
  padding: 8px 12px;
  padding-top: 22px;
  padding-right: 32px;

  color: var(--white);
  border: none;
  border-radius: 6px;
  background-color: var(--dark-next, var(--dark-12));
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}

.withoutValue {
  color: var(--dark-48);
}

.mobileNativeSelect:disabled {
  opacity: 50%;
}

.mobileNativeSelect::-ms-expand {
  display: none;
}

.mobileNativeSelectArrow {
  width: 16px;
  height: 16px;
  stroke: var(--dark-48);
}

.mobileNativeSelectLabel {
  font-size: 12px;
  line-height: 14px;

  position: absolute;
  top: 8px;
  left: 12px;
}

.mobileSelect > * {
  height: 48px !important;
}

.indicatorWrapper {
  position: absolute;
  top: 18px;
  right: 12px;

  width: 24px;
  height: 24px;

  stroke: var(--dark-48);

  pointer-events: none;
}
