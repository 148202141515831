.container {
  position: fixed;
  z-index: 21;
  bottom: 0;

  display: flex;
  justify-content: flex-end;

  width: inherit;
  height: var(--feed-footer-height);
  padding: 16px var(--wrapper-padding);

  background-color: var(--color-wrapper-bg);
  box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.04);
}
