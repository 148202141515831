.container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 16px 0;
  gap: 10px;
}

.wrapperContainer {
  position: relative;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.showMoreContainer {
  position: absolute;
  right: 0;
}

.showMoreButton {
  display: flex;
  align-items: center;

  cursor: pointer;

  opacity: 0.48;
  background-color: transparent;
}

.showMoreButton:hover {
  opacity: 0.6;
}

.showMoreButton:active {
  opacity: 0.75;
}

.showMoreIcon {
  width: 16px;
  height: 16px;
  margin-left: 4px;

  transition: transform 150ms ease-in-out;
}

.showMoreButtonActive > .showMoreIcon {
  transform: rotate(180deg);
}
